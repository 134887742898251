<template>
  <div class="col-lg-7 col-xl-3">
    <div>
      <div>
        <div class="card">
          <img
            class="card-img"
            v-if="oneProgramme.imagePortrait != null"
            :src="oneProgramme.imagePortrait"
            alt="Card image"
          />
          <img
            class="card-img"
            v-else
            src="../../assets/logos/unavailable-image.jpg"
            alt="Card image"
          />
          <span
            style="
              position: absolute;
              top: 83%;
              right: 3%;
              background-color: #dfdfdf;
              border-radius: 5px;
            "
          >
            {{ oneProgramme.duration }}
          </span>
        </div>
        <div
          style="
            margin-top: -26px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div></div>
          <div>
            <b-dropdown
              class="card-drop"
              variant="white"
              menu-class="dropdown-menu-end"
              right
              toggle-class="p-0"
            >
              <template v-slot:button-content>
                <span
                  ><i class="mdi mdi-dots-horizontal font-size-18"></i
                ></span>
              </template>
              <b-dropdown-item
                :href="`/programmes/overview/${oneProgramme.uuid}`"
                >Détails</b-dropdown-item
              >
              <b-dropdown-item @click="showUpdateMetadonnees()"
                >Modifier</b-dropdown-item
              >
              <b-dropdown-item @click="confirm()">supprimer</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <h5
          class="card-title capitalize"
          style="
            font-size: 1.1em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 20px;
          "
        >
          {{ oneProgramme.title }}
        </h5>
      </div>
    </div>
  </div>

  <b-modal
    v-model="updateModal"
    id="modal-xl"
    size="xl"
    title="Modifier le programme"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <b-alert v-model="isAuthError" variant="danger" class="mt-1" dismissible>
        {{ authError }}
      </b-alert>
      <form>
        <div class="row">
          <div class="form-group col-lg-6 mb-3">
            <label for="projectname" class="col-form-label">Titre</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="programme.titre"
                placeholder="Entrez le titre du programme"
              />
            </div>
          </div>

          <div class="form-group col-lg-6 mb-3">
            <label class="col-form-label">Durée du programme</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                v-model="programme.duree"
                class="form-control"
                placeholder="hh:mm:ss"
              />
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="form-group col-lg-4">
            <label for="projectbudget" class="col-form-label"
              >Image Portrait</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <input
                id="projectbudget"
                name="projectbudget"
                type="file"
                @change="imageFileSeelcted($event)"
                class="form-control"
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </div>

          <div class="form-group col-lg-4">
            <label for="projectbudget" class="col-form-label"
              >Image plateau (Paysage)</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <input
                id="projectbudget"
                name="projectbudget"
                type="file"
                @change="imagePFileSeelcted($event)"
                class="form-control"
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </div>
          <div class="form-group col-lg-4">
            <label for="projectbudget" class="col-form-label"
              >Vidéo de couverture</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <input
                id="projectbudget"
                name="projectbudget"
                type="file"
                @change="videoSelect($event)"
                class="form-control"
                accept=".MKV, .AVI, .MP4, MOV"
              />
            </div>
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="projectbudget" class="col-form-label">Description</label>

          <div class="col-lg-12">
            <textarea
              id="projectdesc"
              class="form-control"
              rows="3"
              v-model="programme.description"
              placeholder="Entrez la description"
            ></textarea>
          </div>
        </div>
      </form>

      <br />
      <div class="row">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <addButton :text="'Modifier'" @click="updateProgramme"></addButton>
        </div>
      </div>
    </div>
  </b-modal>
  <loader-process :visible="process" :progress="progress" :title="titre" />
</template>

<script>
import loaderProcess from "./loaderProcess.vue";
import Swal from "sweetalert2";
import { Api } from "../../helpers";
import { Erreur } from "../../helpers/error";
import addButton from "./addButton.vue";

export default {
  name: "ProgrammeCard",
  components: {
    loaderProcess,
    addButton,
  },
  data() {
    return {
      process: false,
      progress: 0,
      titre: "",
      updateMode: "",
      detailProgram: false,
      updateModal: false,
      programme: {
        titre: "",
        description: "",
        duree: "",
        process: false,
      },
      imageSelectFile: new File([], "test"),
      imagePSelectFile: new File([], "test"),
      selectVideo: new File([], "test"),
    };
  },
  props: {
    oneProgramme: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.programme.titre = this.oneProgramme.title;
    this.programme.description = this.oneProgramme.description;
    this.programme.duree = this.oneProgramme.duration;
  },
  methods: {
    showUpdateMetadonnees() {
      this.updateModal = true;
      this.updateMode = "metadonnees";
    },

    confirm() {
      Swal.fire({
        title: "Voulez vraiment supprimer ce programme ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteProgramme();
        }
      });
    },

    imageFileSeelcted(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.imageSelectFile = renamedFile;
      }

      console.log(this.imageSelectFile);
    },

    imagePFileSeelcted(event) {
      console.log(event);
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.imagePSelectFile = renamedFile;
      }

      console.log(this.imagePSelectFile);
    },

    videoSelect(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.selectVideo = renamedFile;
      }

      console.log(this.selectVideo);
    },

    updateProgramme() {
      this.process = true; // Set the process variable to true
      this.updateModal = false; // Hide the modal

      this.process = true;
      this.titre = "Modification du programme";

      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      Api.putFormData(
        `/streamvod/rest/v2/program/update/${this.oneProgramme.id}`,
        {
          title: this.programme.titre,
          duration: this.programme.duree,
          description: this.programme.description,
          landscape: this.imagePSelectFile,
          portrait: this.imageSelectFile,
          video_cover: this.selectVideo,
        },
        { onUploadProgress }
      )
        .then(function (response) {
          this.process = false;
          Swal.fire("Succès!", "Programme supprimé avec succès", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    deleteProgramme() {
      this.process = true;
      this.titre = "Suppréssion du programme";

      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      Api.delete("/streamvod/rest/v2/program/delete/" + this.oneProgramme.id, {
        onUploadProgress,
      })
        .then(() => {
          this.process = true;
          Swal.fire("Succès!", "Programme supprimé avec succès", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = true;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 150px;
  width: 100%;
  border-radius: 9px;
}

.img-caroussel {
  object-fit: cover;
  height: 300px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>
