<script>
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import sectionCard from "./component/sectionCard.vue";
// import addButton from "../../../components/widgets/addButton.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import vueDropzone from "dropzone-vue3";
import sectionImageCard from "./component/sectionImageCard.vue";
import sectionProgramCard from "./component/sectionProgramCard.vue";
import sectionVideoCard from "./component/sectionVideoCard.vue";
import loaderProgress from "../../../components/widgets/loaderProcess.vue";

/**
 * Projects-create component
 */
export default {
  page: {
    title: "Video list",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    // addButton,
    // sectionCard,
    vueDropzone,
    sectionImageCard,
    sectionProgramCard,
    sectionVideoCard,
    loaderProgress,
  },
  setup() {
    const dropzoneOptions = ref({
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 0.5,
      headers: { "My-Awesome-Header": "header value" },
      acceptedFiles: "image/*",
    });

    const acceptedFiles = ref([]);
    const myVueDropzone = ref(null);

    const generateNewFileName = (file) => {
      const timestamp = new Date().getTime();
      const newName = `${file.name}_${timestamp}`;
      return newName;
    };

    const onUploadSuccess = (file, response) => {
      console.log("Upload success:", response);
      getAllFiles();
    };

    const onFileAdded = (file) => {
      const newName = generateNewFileName(file);
      const renamedFile = new File([file], newName, { type: file.type });
      acceptedFiles.value.push({
        originalFile: file,
        newName: newName,
        renamedFile: renamedFile,
      });
      console.log("File added:", renamedFile);
    };

    const getAllFiles = () => {
      if (myVueDropzone.value) {
        const dropzoneComponent = myVueDropzone.value.dropzone;
        const files = dropzoneComponent.getAcceptedFiles();
        acceptedFiles.value = files.map((file) => {
          const newName = generateNewFileName(file);
          return {
            originalFile: file,
            newName: newName,
            renamedFile: new File([file], newName, { type: file.type }),
          };
        });
        console.log("All accepted files:", acceptedFiles.value);
      }
    };

    return {
      dropzoneOptions,
      onUploadSuccess,
      onFileAdded,
      getAllFiles,
      acceptedFiles,
      myVueDropzone,
    };
  },
  data() {
    return {
      process: false,
      spinner: false,
      progress: 0,
      titre: "",
      searchQuery: "",
      searchProgram: "",
      addMovieModal: false,
      choiceProgramModal: false,
      addSectionMovieModal: false,
      addSectionProgramModal: false,
      addSectionImageModal: false,
      SectionMovieModalSelectList: false,
      SectionProgramModalSelectList: false,
      title: "Sections",
      items: [
        {
          text: "Sections",
          active: true,
        },
      ],
      daterange: ref(new Date()),
      videos: [],
      programs: [],
      tags: [],
      programmes: [],
      categorieList: [],
      idPrograms: [],
      idVideos: [],
      sectionPrograms: {
        title: "",
        description: "",
      },
      sectionVideos: {
        title: "",
        description: "",
      },
      sectionImage: {
        title: "",
        description: "",
      },
      sectionsVideoList: [],
      sectionsImageList: [],
      sectionsProgramsList: [],
      filesImages: [],
    };
  },
  methods: {
    showAddMovieModal() {
      this.addMovieModal = !this.addMovieModal;
    },
    showChoiceProgramModall() {
      this.choiceProgramModal = !this.choiceProgramModal;
    },
    showAddSectionMovieModal() {
      this.addMovieModal = false;
      this.addSectionMovieModal = true;
      this.SectionMovieModalSelectList = false;
    },
    showAddSectionProgramModal() {
      this.addMovieModal = false;
      this.addSectionProgramModal = true;
      this.SectionProgramModalSelectList = false;
    },
    showAddSectionImageModal() {
      this.addMovieModal = false;
      this.addSectionImageModal = !this.addSectionImageModal;
    },
    showSectionMovieModalSelectList() {
      this.addSectionMovieModal = false;
      this.SectionMovieModalSelectList = true;
    },
    showSectionProgramModalSelectList() {
      this.addSectionProgramModal = false;
      this.SectionProgramModalSelectList = true;
    },

    imagePortrait(event) {
      console.log(event.target.files);

      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.filesImages.push(renamedFile);
      }

      console.log(this.filesImages);
    },

    imagePaysage(event) {
      console.log(event.target.files);

      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.filesImages.push(renamedFile);
      }

      console.log(this.filesImages);
    },

    getVideos() {
      Api.get("/streamvod/api/v2/video/all")
        .then((res) => {
          this.videos = res.data.content;
          console.log(res);
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getTags() {
      Api.get("/streamvod/api/v2/tag/all")
        .then((res) => {
          this.tags = res.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getPrograms() {
      Api.get("/streamvod/api/v2/program/all")
        .then((res) => {
          this.programmes = res.data.content;
          console.log(res);
        })
        .catch((error) => {
          Erreur.gestionErreur(error.message);
        });
    },

    getCategories() {
      Api.get("/streamvod/api/v2/category/all")
        .then((res) => {
          this.categories = res.data.content;
          this.categorieList = res.data.content;
        })
        .catch((error) => {
          Erreur.gestionErreur(error.message);
        });
    },

    getprograms() {
      Api.get("/streamvod/api/v2/program/all")
        .then((res) => {
          this.programs = res.data.content;
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getSectionVideo() {
      Api.get("/streamvod/api/v2/video-section/all")
        .then((response) => {
          this.sectionsVideoList = response.data.content;
          console.log(this.sectionsVideoList);
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getSectionImage() {
      Api.get("/streamvod/api/v2/image-section/all")
        .then((response) => {
          this.spinner = false;
          this.sectionsImageList = response.data.content;
          console.log(this.sectionsImageList);
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    getSectionPrograms() {
      Api.get("/streamvod/api/v2/program-section/all")
        .then((response) => {
          this.sectionsProgramsList = response.data.content;
          console.log(this.sectionsProgramsList);
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    createSectionVideo() {
      this.process = true;
      this.SectionMovieModalSelectList = false;
      this.addMovieModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout d'une section de vidéo";

      Api.post(
        `/streamvod/rest/v2/video-section/create`,
        {
          videoIds: this.idVideos,
          videoSection: {
            description: this.sectionVideos.description,
            isInProgram: false,
            isOnHomePage: true,
            title: this.sectionVideos.title,
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    createSectionPrograms() {
      this.process = true;
      this.SectionProgramModalSelectList = false;
      this.addMovieModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout d'une section de programme";
      Api.post(
        `/streamvod/rest/v2/program-section/create`,
        {
          programIds: this.idPrograms,
          programSection: {
            description: this.sectionPrograms.description,
            isInProgram: false,
            isOnHomePage: true,
            title: this.sectionPrograms.title,
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    createSectionImage() {
      this.process = true;
      this.addSectionImageModal = false;
      this.addSectionImageModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout d'une section d'image";

      Api.postFormData(
        `/streamvod/rest/v2/image-section/create?description=${this.sectionImage.description}&title=${this.sectionImage.title}`,
        {
          files: this.acceptedFiles,
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },

  computed: {
    filteredVideoList() {
      if (!this.searchQuery) {
        return this.videos;
      }
      return this.videos.filter((video) => {
        const fullName = video.title;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    filteredProgramsList() {
      if (!this.searchProgram) {
        return this.programs;
      }
      return this.programs.filter((program) => {
        const fullName = program.title;
        return fullName
          .toLowerCase()
          .includes(this.searchProgram.toLowerCase());
      });
    },
  },

  mounted() {
    this.spinner = true;
    this.getVideos();
    this.getprograms();
    this.getPrograms();
    this.getTags();
    this.getCategories();

    this.getSectionVideo();
    this.getSectionImage();
    this.getSectionPrograms();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProgress :visible="process" :progress="progress" :title="titre" />

    <div
      style="display: flex; justify-content: space-between; margin-bottom: 2rem"
    >
      <div></div>

      <button class="btn" style="background-color: #07693a; padding: 0%">
        <b-dropdown
          class="mb-0"
          toggle-class="btn btn-link text-muted"
          menu-class="dropdown-menu-end"
          right
          variant="white"
        >
          <template #button-content style="background-color: #07693a">
            <span style="color: white"
              ><i class="fas fa-plus font-size-18"></i> Créer une section</span
            >
          </template>
          <b-dropdown-item @click="showAddMovieModal()"
            ><span
              ><i class="fas fa-plus font-size-18"></i> Section de la page
              d'accueil</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="showChoiceProgramModall()"
            ><span
              ><i class="fas fa-plus font-size-18"></i> Section d'un
              programme</span
            ></b-dropdown-item
          >
        </b-dropdown>
      </button>
      <!-- <addButton @click="showAddMovieModal()"></addButton> -->
    </div>

    <!-- Modal pour le choix de l'action sur les section de programme -->
    <b-modal
      v-model="choiceProgramModal"
      id="modal-lg"
      size="s"
      title="Que souhaitez-vous faire?"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div class="row">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showImportModalRef"
          >
            Créer une nouvelle section
          </button>
        </div>
        <div class="row mt-2">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showImportModalRef"
          >
            Modifier une section existante
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Modal pour le choix du type de section a créer -->
    <b-modal
      v-model="addMovieModal"
      id="modal-lg"
      size="s"
      title="Quel type de section souhaitez-vous créer?"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div class="row">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showAddSectionImageModal()"
          >
            Section d’images
          </button>
        </div>
        <div class="row mt-2">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showAddSectionMovieModal()"
          >
            Section de vidéos
          </button>
        </div>
        <div class="row mt-2">
          <button
            type="submit"
            style="border-radius: 8px; width: 100%"
            class="btn c2play-primary"
            @click="showAddSectionProgramModal()"
          >
            Section de programmes
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Liste des sections -->

    <div style="text-align: center; font-size: 3em" v-if="spinner == true">
      <b-spinner variant="success" role="status"></b-spinner>
    </div>
    <div
      style="text-align: center; font-size: 3em"
      v-if="
        spinner == false &&
        sectionsImageList.length == 0 &&
        sectionsProgramsList.length == 0 &&
        sectionsVideoList.length == 0
      "
    >
      <span style="font-size: 1.5rem">Aucune section créée !</span>
    </div>

    <div role="tablist">
      <b-card no-body class="mb-1 shadow-none">
        <b-card-header header-tag="header" role="tab">
          <h6 class="m-0">
            <a
              v-b-toggle.accordion-1
              class="text-dark"
              href="javascript: void(0);"
              ><h3 v-if="sectionsImageList.length > 0">Sections d'images</h3></a
            >
          </h6>
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <sectionImageCard
                v-for="section in sectionsImageList"
                :key="section.id"
                :section="section"
              ></sectionImageCard>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <div
        v-if="
          programmes.length > 0 && tags.length > 0 && categorieList.length > 0
        "
      >
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <h6 class="m-0">
              <a
                v-b-toggle.accordion-2
                class="text-dark"
                href="javascript: void(0);"
                ><h3 v-if="sectionsProgramsList.length > 0">
                  Sections de programmes
                </h3></a
              >
            </h6>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <div class="row">
                  <sectionProgramCard
                    v-for="section in sectionsProgramsList"
                    :key="section.id"
                    :section="section"
                    :program-list="programmes"
                  ></sectionProgramCard>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" role="tab">
            <h6 class="m-0">
              <a
                v-b-toggle.accordion-3
                class="text-dark"
                href="javascript: void(0);"
                ><h3 v-if="sectionsVideoList.length > 0">
                  Sections de vidéos
                </h3></a
              >
            </h6>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <div class="row">
                  <sectionVideoCard
                    v-for="section in sectionsVideoList"
                    :key="section.id"
                    :section="section"
                    :video-list="videos"
                    :program-list="programmes"
                    :tag-list="tags"
                    :categorie-list="categorieList"
                  ></sectionVideoCard>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>

    <!-- Modal pour la création d'une section de vidéo -->
    <b-modal
      v-model="addSectionMovieModal"
      id="modal-xl"
      size="xl"
      title="Créer un section de vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionVideos.title"
                  placeholder="Entrez le titre"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionVideos.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="display: flex; align-items: center; justify-content: end">
          <button
            @click="showSectionMovieModalSelectList()"
            class="btn c2play-primary"
          >
            suivant
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="SectionMovieModalSelectList"
      id="modal-xl"
      size="xl"
      title="Créer un section de vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <button
              @click="showAddSectionMovieModal()"
              class="btn c2play-primary"
            >
              Retour
            </button>
          </div>
          <div>
            <button @click="createSectionVideo()" class="btn c2play-primary">
              Ajouter
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />

        <div class="table-responsive">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col">Titre</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="video in filteredVideoList.slice().reverse()"
                :key="video.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="idVideos"
                    :value="video.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="video.thumbnail"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ video.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- =============================================================================================== -->

    <!-- Modal pour la création d'une section de programme -->
    <!-- =================================================================================================== -->
    <b-modal
      v-model="addSectionProgramModal"
      id="modal-xl"
      size="xl"
      title="Créer un section de programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionPrograms.title"
                  placeholder="Entrez le titre de la section"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionPrograms.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="display: flex; align-items: center; justify-content: end">
          <button
            @click="showSectionProgramModalSelectList()"
            class="btn c2play-primary"
          >
            Suivant
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="SectionProgramModalSelectList"
      id="modal-xl"
      size="xl"
      title="Créer une section de programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <button
              @click="showAddSectionProgramModal()"
              class="btn c2play-primary"
            >
              Retour
            </button>
          </div>
          <div>
            <button @click="createSectionPrograms()" class="btn c2play-primary">
              Ajouter
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />

        <div class="table-responsive">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col">Titre</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="program in filteredProgramsList.slice().reverse()"
                :key="program.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="idPrograms"
                    :value="program.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="program.imagePortrait"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ program.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- ================================================================================== -->

    <!-- Modal pour la création d'une section d'image -->
    <!-- ========================================================================================= -->
    <b-modal
      v-model="addSectionImageModal"
      id="modal-xl"
      size="xl"
      title="Créer un section d'images"
      title-class="font-18"
      hide-footer
    >
      <div>
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionImage.title"
                  placeholder="Entrez le titre de la section"
                />
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="form-group col-lg-12">
              <label for="projectbudget" class="col-form-label">Images</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-success="onUploadSuccess"
                  @vdropzone-added-file="onFileAdded"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionImage.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="text-align: center">
          <button @click="createSectionImage()" class="btn c2play-primary">
            Ajouter
          </button>
        </div>
      </div>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
