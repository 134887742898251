<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card pt-4" style="padding-left: 2%; padding-right: 2%">
          <div class="row">
            <h2 class="col-lg-10">{{ section.title }}</h2>
            <div class="col-lg-2">
              <div style="display: flex; justify-content: end">
                <div>
                  <!-- <router-link to="/programmes/overview">
                    <span
                      style="color: #0dcaf0; cursor: pointer; font-size: 1.8rem"
                      ><i class="mdi mdi-information"></i></span
                  ></router-link> -->
                  <span
                    @click="showupdateSectionProgramModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-pencil-circle"></i
                  ></span>
                  <span
                    @click="showAddProgramInSectionModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-plus-circle"></i
                  ></span>
                  <span
                    @click="showRemoveProgramInSectionModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-minus-circle"></i
                  ></span>
                  <span
                    @click="confirm()"
                    style="color: #dc3545; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-delete-circle"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="row display">
                <programmeCard
                  v-for="programme in section.programs.slice().reverse()"
                  :key="programme.id"
                  :one-programme="programme"
                ></programmeCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="updateSectionProgramModal"
      id="modal-xl"
      size="xl"
      title="Modifier une section de programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionPrograms.title"
                  placeholder="Entrez le titre"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionPrograms.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <button @click="updateSectionPrograms()" class="btn c2play-primary">
            Modifier
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="addProgramInSectionModal"
      id="modal-xl"
      size="xl"
      title="Ajouter un programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div></div>
          <div>
            <button @click="addProgramInSection()" class="btn c2play-primary">
              Ajouter
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />

        <div class="table-responsive">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col">Titre</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="program in programList.slice().reverse()"
                :key="program.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="addProgramId"
                    :value="program.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="program.imagePortrait"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ program.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="removeProgramInSectionModal"
      id="modal-xl"
      size="xl"
      title="Retirer une vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div></div>
          <div>
            <button
              @click="removeProgramInSection()"
              class="btn c2play-primary"
            >
              Retirer
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />

        <div class="table-responsive">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col">Titre</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="program in section.programs.slice().reverse()"
                :key="program.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="removeProgramId"
                    :value="program.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="program.imagePortrait"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ program.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
  <loaderProgress :visible="process" :progress="progress" :title="titre" />
</template>
<script>
import { Api } from "../../../../helpers";
import { Erreur } from "../../../../helpers/error";
import Swal from "sweetalert2";
import programmeCard from "../../../../components/widgets/programmeCard.vue";
import loaderProgress from "../../../../components/widgets/loaderProcess.vue";
export default {
  name: "SectionImage",
  components: {
    programmeCard,
    loaderProgress,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    programList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      process: false,
      progress: 0,
      titre: "",
      programs: [],
      removeProgramId: [],
      addProgramId: [],
      updateSectionProgramModal: false,
      addProgramInSectionModal: false,
      removeProgramInSectionModal: false,
      sectionPrograms: {
        title: "",
        description: "",
        idPrograms: [],
      },
    };
  },
  methods: {
    showupdateSectionProgramModal() {
      this.updateSectionProgramModal = true;
    },
    showRemoveProgramInSectionModal() {
      this.removeProgramInSectionModal = true;
    },
    showAddProgramInSectionModal() {
      this.addProgramInSectionModal = true;
    },

    updateSectionPrograms() {
      this.process = true;
      this.updateSectionProgramModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Modification de la section";

      Api.put(
        `/streamvod/rest/v2/program-section/update/${this.section.id}`,
        {
          programIds: this.sectionPrograms.idPrograms,
          programSection: {
            description: this.sectionPrograms.description,
            isInProgram: false,
            isOnHomePage: true,
            title: this.sectionPrograms.title,
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Modifiée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    removeProgramInSection() {
      this.process = true;
      this.removeProgramInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Modification de la section";
      const data = this.removeProgramId; // Assurez-vous que this.removeVideoId est un tableau

      Api.delete(
        `/streamvod/rest/v2/program-section/remove-programs/${this.section.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
          data: data,
        },
        { onUploadProgress }
      )
        .then(() => {
          Swal.fire("Deleted!", "Vidéo(s) retirée(s) de la section", "success");
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    addProgramInSection() {
      this.process = true;
      this.addProgramInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Modification de la section";

      const data = this.addProgramId; // Assurez-vous que this.removeVideoId est un tableau

      Api.post(
        `/streamvod/rest/v2/program-section/add-programs/${this.section.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          Swal.fire("Add!", "Vidéo(s) ajoutée(s) de la section", "success");
          // location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    confirm() {
      Swal.fire({
        title: "Voulez vraiment supprimer cette section de programme ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteSection();
        }
      });
    },

    deleteSection() {
      this.process = true;
      this.addProgramInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Suppréssion de la section";
      Api.delete("/streamvod/rest/v2/program-section/delete/" + this.section.id)
        .then(
          () => {
            Swal.fire(
              "Deleted!",
              "Section de programme supprimée avec succès",
              "success"
            );
            location.reload();
          },
          { onUploadProgress }
        )
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.sectionPrograms.title = this.section.title;
    this.sectionPrograms.description = this.section.description;
    this.programs = this.section.programs;
    this.programs.forEach((item) => {
      this.sectionPrograms.idPrograms.push(item.id);
    });
    console.log(this.programList);
  },
};
</script>

<style lang="scss" scoped></style>
