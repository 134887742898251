<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card pt-4" style="padding-left: 2%; padding-right: 2%">
          <div class="row">
            <h2 class="col-lg-10">{{ section.title }}</h2>
            <div class="col-lg-2">
              <div style="display: flex; justify-content: end">
                <div>
                  <!-- <router-link to="/programmes/overview">
                    <span
                      style="color: #0dcaf0; cursor: pointer; font-size: 1.8rem"
                      ><i class="mdi mdi-information"></i></span
                  ></router-link> -->
                  <span
                    @click="showUpdateSectionImageModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-pencil-circle"></i
                  ></span>

                  <span
                    @click="showAddImageInSectionModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-plus-circle"></i
                  ></span>
                  <span
                    @click="confirm()"
                    style="color: #dc3545; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-delete-circle"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-6"
              v-for="image in section.images"
              :key="image.id"
            >
              <div class="card">
                <div style="position: absolute; top: 0; right: 0">
                  <span
                    @click="confirmRemoveImage(image.id)"
                    style="color: #dc3545; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-delete-circle"></i
                  ></span>
                </div>
                <img
                  :src="`${image.url}`"
                  alt="image"
                  style="border-radius: 7px; height: 200px"
                />
              </div>
            </div>
            <!-- <div class="col-lg-6">
              <div class="card">
                <img
                  :src="`${section.images[1].url}`"
                  alt="image"
                  style="border-radius: 7px; height: 200px"
                />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="updateSectionImageModal"
      id="modal-xl"
      size="xl"
      title="Modififer une section d'images"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionImage.title"
                  placeholder="Entrez le titre de la section"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionImage.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <button @click="updateSectionImage()" class="btn c2play-primary">
          Modifier
        </button>
      </div>
    </b-modal>

    <b-modal
      v-model="addImageInSectionModal"
      id="modal-xl"
      size="m"
      title="Ajouter une images"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row mb-3">
            <div class="form-group col-lg-12">
              <label for="projectbudget" class="col-form-label">Image</label>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imagePaysage($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
          </div>
        </form>
        <button @click="addImageInSection()" class="btn c2play-primary">
          Ajouter
        </button>
      </div>
    </b-modal>
  </div>
  <loaderProgress :visible="process" :progress="progress" :title="titre" />
</template>

<script>
import { Api } from "../../../../helpers";
import { Erreur } from "../../../../helpers/error";
import Swal from "sweetalert2";
import loaderProgress from "../../../../components/widgets/loaderProcess.vue";
export default {
  name: "SectionImage",
  components: {
    loaderProgress,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      process: false,
      progress: 0,
      titre: "",
      updateSectionImageModal: false,
      addImageInSectionModal: false,
      file: null,
      sectionImage: {
        title: "",
        description: "",
      },
    };
  },
  methods: {
    showUpdateSectionImageModal() {
      this.updateSectionImageModal = !this.updateSectionImageModal;
    },
    showAddImageInSectionModal() {
      this.addImageInSectionModal = true;
    },
    imagePaysage(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.file = renamedFile;
      }

      console.log(this.file);
    },
    addImageInSection() {
      this.process = true;
      this.addImageInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout d'une image dans la section";

      Api.postFormData(
        `/streamvod/rest/v2/image/add-image?section_image_id=${this.section.id}`,
        {
          file: this.file,
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Image ajoutée", "success");
          // location.reload()
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    updateSectionImage() {
      this.process = true;
      this.addImageInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Modification de la section";

      Api.put(
        `/streamvod/rest/v2/image-section/update/${this.section.id}`,
        {
          title: this.sectionImage.title,
          description: this.sectionImage.description,
        },
        { onUploadProgress }
      )
        .then(function (response) {
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    confirm() {
      Swal.fire({
        title: "Voulez vraiment supprimer cette section ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteSection();
        }
      });
    },

    deleteSection() {
      this.process = true;
      this.addImageInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Suppréssion de la section";

      Api.delete("/streamvod/rest/v2/image-section/delete/" + this.section.id, {
        onUploadProgress,
      })
        .then(() => {
          Swal.fire("Deleted!", "Section supprimée avec succès", "success");
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    confirmRemoveImage(id) {
      Swal.fire({
        title: "Voulez vraiment supprimer cette image ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteImage(id);
        }
      });
    },

    deleteImage(id) {
      this.process = true;
      this.addImageInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Suppréssion d'une image de section";
      Api.delete("/streamvod/rest/v2/image/delete/" + id, { onUploadProgress })
        .then(() => {
          Swal.fire("Deleted!", "Image supprimée avec succès", "success");
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.sectionImage.title = this.section.title;
    this.sectionImage.description = this.section.description;
  },
};
</script>

<style lang="scss" scoped></style>
