<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card pt-4" style="padding-left: 2%; padding-right: 2%">
          <div class="row">
            <h2 class="col-lg-10">{{ section.title }}</h2>
            <div class="col-lg-2">
              <div style="display: flex; justify-content: end">
                <div>
                  <!-- <router-link to="/programmes/overview">
                    <span
                      style="color: #0dcaf0; cursor: pointer; font-size: 1.8rem"
                      ><i class="mdi mdi-information"></i></span
                  ></router-link> -->
                  <span
                    @click="showUpdateSectionMovieModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-pencil-circle"></i
                  ></span>
                  <span
                    @click="showAddVideoInSectionModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-plus-circle"></i
                  ></span>
                  <span
                    @click="showRemoveVideoInSectionModal()"
                    style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-minus-circle"></i
                  ></span>
                  <span
                    @click="confirm()"
                    style="color: #dc3545; cursor: pointer; font-size: 1.8rem"
                    ><i class="mdi mdi-delete-circle"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-container">
            <div class="swiper-wrapper" style="height: 200px">
              <div class="row display">
                <VideosCard
                  v-for="video in section.videos"
                  :key="video.id"
                  :affichage="'Liste'"
                  :video-props="video"
                  :categ="categorieList"
                  :tag="tagList"
                  :programme="programList"
                ></VideosCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="updateSectionMovieModal"
      id="modal-xl"
      size="xl"
      title="Modifier une section de vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionVideos.title"
                  placeholder="Entrez le titre"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionVideos.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <button @click="updateSectionVideo()" class="btn c2play-primary">
            Modifier
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="addVideoInSectionModal"
      id="modal-xl"
      size="xl"
      title="Ajouter une vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div></div>
          <div>
            <button @click="addVideoInSection()" class="btn c2play-primary">
              Ajouter
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />

        <div class="table-responsive">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col">Titre</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="video in videoList.slice().reverse()" :key="video.id">
                <td>
                  <b-form-checkbox
                    v-model="addVideoId"
                    :value="video.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="video.thumbnail"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ video.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="removeVideoInSectionModal"
      id="modal-xl"
      size="xl"
      title="Retirer une vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div></div>
          <div>
            <button @click="removeVideoInSection()" class="btn c2play-primary">
              Retirer
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />

        <div class="table-responsive">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col">Titre</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="video in section.videos.slice().reverse()"
                :key="video.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="removeVideoId"
                    :value="video.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="video.thumbnail"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ video.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
  <loaderProgress :visible="process" :progress="progress" :title="titre" />
</template>
<script>
import { Api } from "../../../../helpers";
import { Erreur } from "../../../../helpers/error";
import Swal from "sweetalert2";
import VideosCard from "../../../../components/widgets/VideosCard.vue";
import loaderProgress from "../../../../components/widgets/loaderProcess.vue";
export default {
  name: "SectionImage",
  components: {
    VideosCard,
    loaderProgress,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    videoList: {
      type: Array,
      required: true,
    },
    programList: {
      type: Array,
      required: true,
    },
    categorieList: {
      type: Array,
      required: true,
    },
    tagList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      process: false,
      progress: 0,
      titre: "",
      updateSectionMovieModal: false,
      removeVideoInSectionModal: false,
      addVideoInSectionModal: false,
      programs: [],
      videos: [],
      removeVideoId: [],
      addVideoId: [],
      sectionVideos: {
        title: "",
        description: "",
        idVideos: [],
      },
    };
  },
  methods: {
    showUpdateSectionMovieModal() {
      this.updateSectionMovieModal = true;
    },
    showRemoveVideoInSectionModal() {
      this.removeVideoInSectionModal = true;
    },
    showAddVideoInSectionModal() {
      this.addVideoInSectionModal = true;
    },

    updateSectionVideo() {
      this.process = true;
      (this.updateSectionMovieModal = false), (this.process = true);
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Modification de la section";

      Api.put(
        `/streamvod/rest/v2/video-section/update/${this.section.id}`,
        {
          videoIds: this.sectionVideos.idVideos,
          videoSection: {
            description: this.sectionVideos.description,
            isInProgram: false,
            isOnHomePage: true,
            title: this.sectionVideos.title,
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Modifiée avec succès", "success");
          location.reload()
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

    removeVideoInSection() {
      this.process = true;
      this.removeVideoInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };

      this.titre = "Modification de la section";
      const data = this.removeVideoId; // Assurez-vous que this.removeVideoId est un tableau

      Api.delete(
        `/streamvod/rest/v2/video-section/remove-videos/${this.section.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
          data: data,
        },
        { onUploadProgress }
      )
        .then(() => {
          Swal.fire("Deleted!", "Vidéo(s) retirée(s) de la section", "success");
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    addVideoInSection() {
      this.process = true;
      this.addVideoInSectionModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };

      this.titre = "Modification de la section";
      const data = this.addVideoId; // Assurez-vous que this.removeVideoId est un tableau

      Api.post(
        `/streamvod/rest/v2/video-section/add-videos/${this.section.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }, {onUploadProgress}
      )
        .then(() => {
          Swal.fire("Add!", "Vidéo(s) ajoutée(s) de la section", "success");
          location.reload();
        })
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    confirm() {
      Swal.fire({
        title: "Voulez vraiment supprimer cette section de vidéo ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteSection();
        }
      });
    },

    deleteSection() {
      this.process = true;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Suppréssion de la section";
      Api.delete("/streamvod/rest/v2/video-section/delete/" + this.section.id)
        .then(
          () => {
            Swal.fire(
              "Deleted!",
              "Section de Vidéo supprimée avec succès",
              "success"
            );
            location.reload();
          },
          { onUploadProgress }
        )
        .catch((error) => {
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

  },
  mounted() {
    this.sectionVideos.title = this.section.title;
    this.sectionVideos.description = this.section.description;
    this.videos = this.section.videos;
    this.videos.forEach((item) => {
      this.sectionVideos.idVideos.push(item.id);
    });
  },

  computed: {
    filteredVideoList() {
      if (!this.searchQuery) {
        return this.videos;
      }
      return this.videos.filter((video) => {
        const fullName = video.title;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
